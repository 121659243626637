body{
  background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(255,250,235,1) 0%, rgba(156,173,209,0.9699230033810399) 100%);
font-family: 'Anek Bangla', sans-serif;
}

@media screen and (max-width:768px){
  .box{
    width: 75% !important;
  }
}